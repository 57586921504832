import React, { useState } from 'react';
import {
  IonItem,
  IonIcon,
  IonLabel,
  IonText,
  IonButton
} from '@ionic/react';
import {
  hourglass,
  pricetag,
  ellipsisVertical,
  closeCircle
} from 'ionicons/icons';
import { AddSpecialModal, DeleteSpecialModal } from './modals';
import { actionSheet } from '../core';

export interface ItemProps {
  id: number;
  title: string;
  details: string;
  starts_at: Date;
  ends_at?: Date;
}

interface SpecialRowProps {
  item: ItemProps
  refresher?: () => void;
  clientId: number;
}

const SpecialRow: React.FC<SpecialRowProps> = ({ item, refresher, clientId }) => {
  const { title, details, starts_at, ends_at } = item;
  const startDate = new Date(starts_at);
  const endDate = !!ends_at ? new Date(ends_at) : null;
  const now = new Date();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const getIcon = () => {
    if (now < startDate) return hourglass;
    if (endDate !== null && endDate < now) return closeCircle;
    return pricetag;
  }

  const dateString = (date: Date) => {
    return new Date(date).toISOString().split('T')[0];
  }

  const handleMenuClick = () => {
    const buttons = [
      {
        text: 'Edit',
        handler: () => {
          setEditModalOpen(true);
        }
      }
    ]
    if (!endDate || endDate > now) {
      buttons.push(
        {
          text: 'End',
          handler: () => {
            setDeleteModalOpen(true);
          }
        }
      )
    }
    actionSheet.show(buttons);
  }

  return (
    <>
      <IonItem className="special-row">
        <IonIcon icon={getIcon()} size="large" style={{ marginRight: '15px' }} />
        <IonLabel className="ion-multiline">
          <IonText>
            <h2>{title}</h2>
            <p className="detail"><em>{dateString(startDate)} - {!!endDate ? dateString(endDate) : 'No expiration'}</em></p>
            <p className="detail">{details}</p>
          </IonText>
        </IonLabel>
        <IonButton slot="end" fill="clear" onClick={handleMenuClick}>
          <IonIcon slot="icon-only" icon={ellipsisVertical} />
        </IonButton>
      </IonItem>
      <AddSpecialModal
        type="edit"
        isOpen={editModalOpen}
        item={item}
        onDidDismiss={() => {
          setEditModalOpen(false)
          refresher && refresher()
        }}
      />
      <DeleteSpecialModal
        isOpen={deleteModalOpen}
        id={item.id}
        onDidDismiss={() => {
          setDeleteModalOpen(false)
          refresher && refresher()
        }}
      />
    </>
  )
}

export default SpecialRow;
