import React, { useContext, useCallback, useState } from 'react';
import { TInfiniteScrollPage } from '../components';
import SpecialRow from 'components/SpecialRow';
import { AppContext } from '../context/AppContext';
import { useDataLoader } from '../hooks';
import specialsService from 'services/specials';
import { useParams } from 'react-router-dom';
import { add } from 'ionicons/icons';
import { IonButton, IonIcon } from '@ionic/react';
import { AddSpecialModal } from 'components/modals';

const SpecialsPage: React.FC = () => {
  let { status } = useParams() as any;
  const { state: appState } = useContext(AppContext);
  const { selectedClient } = appState;
  const [addSpecialOpen, setAddSpecialOpen] = useState(false);

  const loadSpecials = useCallback(async () => {
    return await specialsService.list({
      clientId: selectedClient.id,
      path: status
    });
  }, [selectedClient, status]);
  
  const {
    refresher,
    data,
    error,
    loadNext,
    next,
    loading,
  } = useDataLoader(loadSpecials, true);

  return (
    <>
      <TInfiniteScrollPage
        loading={loading}
        items={data}
        error={error}
        hasNext={!!next}
        renderItem={(item: any) => (
          <SpecialRow
            key={item.id}
            item={item}
            refresher={refresher}
            clientId={selectedClient.id}
          />
        )}
        onLoadNextPage={loadNext}
        onRefresh={refresher}
        headerTool={(
          <IonButton
            fill="clear"
            slot="end"
            onClick={() => setAddSpecialOpen(true)}
          >
            <IonIcon slot="icon-only" color="primary" icon={add} />
          </IonButton>
        )}
        zeroStateText={loading ? '' : 'No specials found.'}
      />
      {addSpecialOpen && (
        <AddSpecialModal
          isOpen={addSpecialOpen}
          onDidDismiss={() => {
            setAddSpecialOpen(false)
            refresher()
          }}
        />
      )}
    </>
  )
}

export default SpecialsPage;
